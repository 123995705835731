/* PaymentHistory.css */

.year-section {
    margin: 20px;
  }
  .month h5 {
    margin-bottom: 0px;
    line-height: normal;
}
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  
  .months-grid {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
    gap: 10px;
  }
  
  .month {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    text-align: center;
  }
  
.month.tick {
    background-color: #d4edda;
    border-color: #c3e6cb;
    color: #155724;
    font-size: 16px;
    padding: 3px 12px;
}
  
  .month.late-yellow {
    background-color: #fff3cd;
    border-color: #ffeeba;
    color: #856404;
  }
  
  .month.late-red {
    background-color: #f8d7da;
    border-color: #f5c6cb;
    color: #721c24;
  }
  
  /* Add general styles */
  .container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
  }
  
  .payment-history {
    margin-top: 20px;
  }
  